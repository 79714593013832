import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { capitalCase } from 'change-case'
import Table from '../components/Table'
import Image from '../components/Image'
import Signature from '../components/Signature'

export default function shippingPackageValidationTemplate({ data }) {
  const { frontmatter } = data.markdownRemark
  return (
    <div className="content">
      <Layout title={frontmatter.productName + ' DHF'}>
        <h1 className="title is-1">{frontmatter.documentName}</h1>
        <Link to={`/${frontmatter.productName}`}>
          {frontmatter.productName} DHF
        </Link>
        <hr />
        <h1 className="title is-1">
          {frontmatter.productTitle} {capitalCase(frontmatter.documentName)}
        </h1>
        <hr />
        Testing Location: {frontmatter.testingLocation}
        <hr />
        Date: {frontmatter.date}
        <hr />
        Controlled, Restricted and Confidential Document
        <hr />
        <h2 className="title is-2 has-text-info-dark">Overview</h2>
        The purpose of this document is to supply the results from testing
        performed on the {frontmatter.inxin} packaging used to package the TZ
        Medical {frontmatter.productName}. The TZ Medical{' '}
        {frontmatter.productName} is packaged with the same plates and packaging
        as the TZ Medical {frontmatter.historicalProductName} product with
        validated data for a {frontmatter.yearsValidated} shelf life. The tests
        in this document provide evidence that the packaging process used by TZ
        Medicals {frontmatter.productName} validates a {frontmatter.years} shelf
        life based on the performance of the packaging during transportation and
        storage. Testing was done in accordance to ISO 11607 standards.{' '}
        {frontmatter.redText}
        <br />
        <br />
        Product is packaged by {frontmatter.locationOfPackaging} on the
        production line with tools used for routine packaging of TZ Medical
        product, and sterilized using the same process defined in Multiple
        Product Sterilization Validation (Doc ID {frontmatter.overviewTextDocId}
        ).
        <br />
        <h2 className="title is-2 has-text-info-dark">Lot Selection</h2>
        <Table table={frontmatter.lotSelectionTable} />
        <h2 className="title is-2 has-text-info-dark">Sample Justification</h2>
        Based on procedure 1-4 and “Product Testing”{' '}
        {frontmatter.totalSamplesRequired} samples are required. We have
        identified that shipping a single item loose packed in a box as the
        highest risk option for shipping the product.{' '}
        {frontmatter.boxesRequiredToShipSamples} boxes of the product will be
        shipped air freight across the country and back and then the individual
        product will be inspected. Based on Qty {frontmatter.samplesPerBox} per
        box we will test {frontmatter.totalSamplesRequired} samples.
        <h2 className="title is-2 has-text-info-dark">Preconditioning</h2>
        <h3 className="title is-5">Required Equipment</h3>
        <Table table={frontmatter.requiredEquipmentTable} />
        <h3 className="title is-5">Sampling</h3>
        <p>
          All samples required in “Sampling Justification” will be run through
          accelerated aging prior to shipping.
        </p>
        <h3 className="title is-5">Procedure</h3>
        <ol>
          <li>
            All package product will be put through accelerated aging prior to
            shipping.
          </li>
          <li>
            Accelerated Aging was conducted in accordance with TZ Medical’s
            Accelerated Aging Protocol (Doc ID{' '}
            {frontmatter.preconditioningProcedureDocID}).
          </li>
          <ol type="A">
            <li>
              Equipment- Low Temperature Oven- Quincy Lab 30GCE-LT Gravity Oven
            </li>
            <li>
              Parameters – (2 Year Shelf Life = 110 days at the below listed
              Temperature and Humidity)
            </li>
            <ol type="a">
              <li>Temperature = 50 C+/- 2 &deg;C </li>
              <li>Ambient Humidity = 20 - 50 ± 5 %RH</li>
              <li>Chamber Humidity = 20 ± 5 %RH</li>
            </ol>
          </ol>
        </ol>
        <Table table={frontmatter.preconditioningProcedureTable} />
        <h2 className="title is-2 has-text-info-dark">Shipping Test</h2>
        <h3 className="title is-5">Requirement(s)</h3>
        <p>
          Packaging must be shipped halfway across the United States and back
          without damage done to the product or packaging.
        </p>
        <h3 className="title is-5">Sampling</h3>
        <p>Refer to “Sampling Justification” section.</p>
        <h3 className="title is-5">Procedure</h3>
        <ol>
          <li>
            Drop each box from 1 meter 10 times onto each of the X, Y, Z axis of
            the box.
          </li>
          <li>
            Ship each box air freight at least half the distance across the
            United States and back.
          </li>
          <ol type="a">
            <li>
              Product shipped to Kyle Robinson in Memphis, Tennessee via UPS
              ground with rep stock and returned.
            </li>
          </ol>
          <li>
            Upon return, visually inspect each product for damages to device,
            the Tyvek material and 10mil plastic.
          </li>
          <li>
            Any damage to the device, puncture in Tyvek or 10mil plastic
            constitutes as a failure.
          </li>
        </ol>
        <h3 className="title is-5">Results</h3>
        <Table table={frontmatter.shippingTestResultsTable} isNumbered={true} />
        <h3 className="title is-5">Testing Performed By:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <h2 className="title is-2 has-text-info-dark">Package Testing</h2>
        <p className="ml-5">
          <h2 className="title is-3 has-text-info">1. Burst Testing</h2>
        </p>
        <h3 className="title is-5">Requirement(s):</h3>
        <p>
          Packaging must have a minimum burst pressure ≥ 26inH<sub>2</sub>O.
        </p>
        <h3 className="title is-5">Equipment required:</h3>
        <Table table={frontmatter.packageTestingEquipmentRequiredTable} />
        <h3 className="title is-5">Sampling</h3>
        Based on Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingBurstTestingSamplingDocId}) section 5.5.3.1,
        the parameters being tested are{' '}
        {frontmatter.packageTestingBurstTestingSamplingLowMediumHigh} risk. Due
        to the repeatability of testing, we expect the mean results to be at
        least {frontmatter.packageTestingBurstTestingSamplingText234} sigma from
        the specification using a minimum of{' '}
        {frontmatter.packageTestingBurstTestingSamplingText5710} samples. If the
        results are not within{' '}
        {frontmatter.packageTestingBurstTestingSamplingText234} sigma, then
        Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingBurstTestingSamplingDocId}) section 5.5.3.3
        should be referenced for additional sampling guidelines.
        <h3 className="title is-5">Procedure:</h3>
        <ol>
          <li>Cut a hole in the 10mil PTFE plastic.</li>
          <li>
            Connect inflation tube to package through the hole cut in the 10mil
            PTFE plastic.
          </li>
          <li>
            Using hot glue to seal area around tubing. Ensure that there is no
            leaking through inflation tube/package interface.
          </li>
          <li>
            Use Aluminum tape cover the entire outside exposed Tyvek so that the
            pouch can be pressurized.
          </li>
          <li>
            Set the Inflation device to inflate with a constant mass flow rate
            (i.e. 1 kg/s)
          </li>
          <li>
            Set the Inflation device to record the pressure over time to extract
            the exact pressure at burst.
          </li>
          <li>Pressurize the package until failure.</li>
          <li>Record the burst pressure value.</li>
          <li>Record the location of Burst.</li>
          <li>Repeat test with 5 samples.</li>
        </ol>
        <h3 className="title is-5">Results:</h3>
        <Table
          table={frontmatter.packageTestingBurstTestingResultsTable}
          isNumbered={true}
        />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.packageTestingBurstTestingConclusionTable} />
        <h3 className="title is-5">Testing Performed By:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <p className="ml-5">
          <h2 className="title is-3 has-text-info">2. Creep Testing</h2>
        </p>
        <h3 className="title is-5">Requirement(s):</h3>
        Packaging should be loaded to 50% of the burst pressure and held for 30
        seconds without losing pressure.
        <h3 className="title is-5">Equipment required:</h3>
        <Table
          table={frontmatter.packageTestingCreepTestingEquipmentRequired}
        />
        <h3 className="title is-5">Sampling</h3>
        Based on Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingCreepTestingSamplingDocId}) section 5.5.3.1,
        the parameters being tested are{' '}
        {frontmatter.packageTestingCreepTestingSamplingLowMediumHigh} risk. Due
        to the repeatability of testing, we expect the mean results to be at
        least {frontmatter.packageTestingCreepTestingSampling234} sigma from the
        specification using a minimum of{' '}
        {frontmatter.packageTestingCreepTestingSampling5710} samples. If the
        results are not within{' '}
        {frontmatter.packageTestingCreepTestingSampling234} sigma, then
        Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingCreepTestingSamplingDocId}) section 5.5.3.3
        should be referenced for additional sampling guidelines.
        <h3 className="title is-5">Procedure:</h3>
        <ol>
          <li>Repeat steps 1-4 in “Burst Testing” procedure.</li>
          <li>Pressurize package to 50% of burst value.</li>
          <li>
            Hold PSI for 30 seconds. Adjusting pressure as necessary to maintain
            at minimum ≥50% of the burst pressure.
          </li>
          <li>Place packaging in water to determine if air is escaping.</li>
          <li>Record pressure attained.</li>
          <li>Record time in seconds</li>
          <li>Record location and type of any failure</li>
          <ol type="a">
            <li>NOTE: Device does not fail if air comes out of Tyvek.</li>
            <li>
              Failure is considered as release of air from the Tyvek seal in a
              time less than 30 seconds.
            </li>
          </ol>
          <li>Repeat test with 5 samples.</li>
        </ol>
        <h3 className="title is-5">Results:</h3>
        <Table
          table={frontmatter.packageTestingCreepTestingResultsTable}
          isNumbered={true}
        />
        <h3 className="title is-5">Testing Performed By</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <p className="ml-5">
          <h2 className="title is-3 has-text-info">3. Dye Penetration</h2>
        </p>
        <h3 className="title is-5">Requirement(s):</h3>
        This may be required when a peel test reveals minor inconsistencies in
        the seal. Dye should be water-based dye and blue. Dye should not
        penetrate seal after 60 seconds.
        <h3 className="title is-5">Equipment required:</h3>
        <Table
          table={frontmatter.packageTestingDyePenetrationEquipmentRequired}
        />
        <h3 className="title is-5">Sampling</h3>
        Based on Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingDyePenetrationSamplingDocId}) section
        5.5.3.1, the parameters being tested are{' '}
        {frontmatter.packageTestingDyePenetrationSamplingTextLowMediumHigh}{' '}
        risk. Due to the repeatability of testing, we expect the mean results to
        be at least {frontmatter.packageTestingDyePenetrationSamplingText234}{' '}
        sigma from the specification using a minimum of{' '}
        {frontmatter.packageTestingDyePenetrationSamplingText5710} samples. If
        the results are not within{' '}
        {frontmatter.packageTestingDyePenetrationSamplingText234} sigma, then
        Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingDyePenetrationSamplingDocId}) section 5.5.3.3
        should be referenced for additional sampling guidelines.
        <h3 className="title is-5">Procedure:</h3>
        <p className="ml-5">
          <ol>
            <h3 className="title is-5">External Application</h3>
            <li>
              Pour the dye solution into a suitable tray, ensure that the depth
              is not greater than the seal width.
            </li>
            <li>
              Immerse the edge of the seal in the solution for 1 – 2 seconds.
            </li>
            <li>
              Examine the seal for evidence of dye penetrating the seal area.
            </li>
            <li>
              If the seal area has not been penetrated within 20 seconds of
              removal from the dye solution, the seal is considered
              satisfactory.
            </li>
            <li>
              If after 20 seconds the seal is within 2.0 mm of being penetrated
              and the dye is still moving through the seal, the seal should
              continue to be examined.
            </li>
            <li>
              If the seal is penetrated within 60 seconds it is considered a
              failure.
            </li>
            <h3 className="title is-5">Internal Application</h3>
            <li>
              For four side sealed pouches, cut the pouch open and remove any
              product.
            </li>
            <li>
              Pour sufficient dye solution into the pouch (approximately 15 mm
              depth).
            </li>
            <li>
              Rotate the pouch so that the dye solution comes into contact with
              the seals to be tested.
            </li>
            <li>
              Examine the seal for evidence of dye penetrating the seal area.
            </li>
            <li>
              If the seal area has not been penetrated within 20 seconds of
              removal from the dye solution, the seal is considered
              satisfactory.
            </li>
            <li>
              If after 20 seconds the seal is within 2.0 mm of being penetrated
              and the dye is still moving through the seal, the seal should
              continue to be examined.
            </li>
            <li>
              If the seal is penetrated within 60 seconds it is considered a
              failure.
            </li>
          </ol>
        </p>
        <h3 className="title is-5">Results:</h3>
        <Table
          table={frontmatter.packageTestingDyePenetrationResultsTable}
          isNumbered={true}
        />
        <h3 className="title is-5">Conclusion:</h3>
        <Table
          table={frontmatter.packageTestingDyePenetrationConclusionTable}
        />
        <h3 className="title is-5">Testing Performed By:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <p className="ml-5">
          <h2 className="title is-3 has-text-info">4. Package Peel Test</h2>
        </p>
        <h3 className="title is-5">Requirement(s)</h3>
        Tyvek packaging seal must withstand a force defined on “Tensile peel
        test” section of “Package Sealing Specification for Terminally
        Sterilized Devices” (kt doc{' '}
        {frontmatter.packageTestingPackagePeelTestRequirementsDocId}).
        <h3 className="title is-5">Equipment required:</h3>
        <Table
          table={
            frontmatter.packageTestingPackagePeelTestEquipmentRequiredTable
          }
        />
        <h3 className="title is-5">Sampling</h3>
        Based on Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingPackagePeelTestSamplingDocId}) section
        5.5.3.1, the parameters being tested are{' '}
        {frontmatter.packageTestingPackagePeelTestSamplingTextLowMediumHigh}{' '}
        risk. Due to the repeatability of testing, we expect the mean results to
        be at least {frontmatter.packageTestingPackagePeelTestSamplingText234}{' '}
        sigma from the specification using a minimum of{' '}
        {frontmatter.packageTestingPackagePeelTestSamplingText5710} samples. If
        the results are not within{' '}
        {frontmatter.packageTestingPackagePeelTestSamplingText234} sigma, then
        Validation Procedures (Doc ID{' '}
        {frontmatter.packageTestingPackagePeelTestSamplingDocId}) section
        5.5.3.3 should be referenced for additional sampling guidelines.
        <h3 className="title is-5">Procedure:</h3>
        <ol>
          <li>
            Cut a 1” wide and 3” long section of the Tyvek seal from each side
            of packaging.
          </li>
          <li>
            Clamp each leg of the test specimen in the Mark-10 Force gauge and
            motorize test stand.
          </li>
          <li>Center the specimen laterally in the clamps.</li>
          <li>
            Align the specimen in the clamps so that the seal line is
            perpendicular to the direction of pull.
          </li>
          <ol type="a">
            <li>
              Allow slack so that the seal is not stressed prior to initiation
              of test.
            </li>
          </ol>
          <li>
            Set the Mark-10 Force gauge at an advancement speed of 200 mm/min.
          </li>
          <li>
            Record the maximum force required to stress specimen to failure.
          </li>
          <li>
            Compare results to the acceptance limit table on “Tensile peel test”
            section of “Package Sealing Specification for Terminally Sterilized
            Devices” (kt doc 13410).
          </li>
          <li>
            Ensure no more than 1 Fiber can be removed from the seal with gloved
            hand.
          </li>
          <li>
            Repeat steps 2-8 for each Tyvek seal on each side of packaging.
          </li>
        </ol>
        <h3 className="title is-5">Results:</h3>
        <Table
          table={frontmatter.packageTestingPackagePeelTestResultsTable}
          isNumbered={true}
        />
        <br />
        <p align="center">
          <Image filepath="Shipping Packaging Validation/PackageTestingPackagePeelTestResults.png" />
        </p>
        <h3 className="title is-5">Conclusion:</h3>
        <Table
          table={frontmatter.packageTestingPackagePeelTestConclusionTable}
        />
        <h3 className="title is-5">Testing Performed By:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <h2 className="title is-2 has-text-info-dark">Product Testing</h2>
        <h3 className="title is-5">Requirement(s):</h3>
        Product must pass validation testing procedure{' '}
        {frontmatter.productTestingRequirementsDocId} to validate product
        performance after storage, or simulated storage, and “Shipping”.
        <h3 className="title is-5">Materials:</h3>
        <Table table={frontmatter.productTestingMaterialsTable} />
        <h3 className="title is-5">Sampling</h3>
        Validation procedures ensure the device will fulfill user needs and will
        be tested on a “Pass” or “Fail” criteria. Based on Risk Analysis,
        parameters being tested are{' '}
        {frontmatter.productTestingSamplingLowMediumHigh} risk. Due to the
        repeatability of testing, we expect there to be zero failures requiring
        a minimum of {frontmatter.productTestingSamplingText5710} samples. If
        product has visual discrepancies or fails validation testing, then
        Validation Procedures (Doc ID{' '}
        {frontmatter.productTestingSamplingTextDocId}) should be referenced for
        additional sampling guidelines.
        <h3 className="title is-5">Procedure</h3>
        <ol>
          <li></li>
        </ol>
        <h3 className="title is-5">Results</h3>
        <Table
          table={frontmatter.productTestingResultsTable}
          isNumbered={true}
        />
        <h3 className="title is-5">Testing Performed By:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
        <h2 className="title is-2 has-text-info-dark">
          Conclusion for Shipping & Packaging Validation
        </h2>
        <h3 className="title is-5">Document Reviewed By:</h3>
        <h3 className="title is-5">Reviewer Qualification:</h3>
        <Signature caption="Signature" hasDate={true} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query MarkdownQueryShippingPackagingValidation($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        documentName
        productName
        templateName
        author
        productTitle

        testingLocation
        date
        inxin
        historicalProductName
        yearsValidated
        years
        redText
        locationOfPackaging
        overviewTextDocId
        lotSelectionTable {
          partNumber
          lotPulledFrom
          datePackaged
          dateSterilized
          shipDate
          returnDate
        }
        totalSamplesRequired
        boxesRequiredToShipSamples
        samplesPerBox
        requiredEquipmentTable {
          partNumber
          description
          calDate
          image
        }
        preconditioningProcedureDocId
        preconditioningProcedureTable {
          lotNumber
          ovenTemperature
          ambientTemperature
          chamberHumidity
          dateIn
          dateOut
          yearsAged
        }
        shippingTestResultsTable {
          sample
          lotIdentifier
          damageToDevicePassOrFail
          damageToTyvekPassOrFail
          damageToPlasticPassOrFail
        }
        packageTestingEquipmentRequiredTable {
          partNumber
          description
          calDate
          image
        }
        packageTestingBurstTestingSamplingDocId
        packageTestingBurstTestingSamplingLowMediumHigh
        packageTestingBurstTestingSamplingText234
        packageTestingBurstTestingSamplingText5710
        packageTestingBurstTestingResultsTable {
          sample
          lotIdentifier
          burstPressurePsi
          locationOfFailure
          passOrFail
        }
        packageTestingBurstTestingConclusionTable {
          partNumber
          mean
          standardDeviation
          specification
          numberOfStandardDeviationFromSpecification
        }
        packageTestingCreepTestingEquipmentRequired {
          partNumber
          description
          calDate
          image
        }
        packageTestingCreepTestingSamplingDocId
        packageTestingCreepTestingSamplingLowMediumHigh
        packageTestingCreepTestingSampling234
        packageTestingCreepTestingSampling5710
        packageTestingCreepTestingResultsTable {
          testNumber
          lotIdentifier
          pressure
          leaksOtherThanTyvek
          passOrFail
        }
        packageTestingDyePenetrationEquipmentRequired {
          partNumber
          description
          calDate
          image
        }
        packageTestingDyePenetrationSamplingDocId
        packageTestingDyePenetrationSamplingTextLowMediumHigh
        packageTestingDyePenetrationSamplingText234
        packageTestingDyePenetrationSamplingText5710
        packageTestingDyePenetrationResultsTable {
          sampleNumber
          externalDyePenetrationInMm
          internalDyePenetrationInMm
          passOrFail
        }
        packageTestingDyePenetrationConclusionTable {
          partNumber
          location
          mean
          standardDeviation
          specification
          numberOfStandardDeviationFromSpecification
        }
        packageTestingPackagePeelTestRequirementsDocId
        packageTestingPackagePeelTestEquipmentRequiredTable {
          partNumber
          description
          calDate
          image
        }
        packageTestingPackagePeelTestSamplingDocId
        packageTestingPackagePeelTestSamplingTextLowMediumHigh
        packageTestingPackagePeelTestSamplingText234
        packageTestingPackagePeelTestSamplingText5710
        packageTestingPackagePeelTestResultsTable {
          testNumber
          lotIdentifier
          bottomSealForceInIbf
          leftSealForceInIbf
          rightSealForceInIbf
          topSealForceInIbf
          passOrFail
        }
        packageTestingPackagePeelTestConclusionTable {
          partNumber
          sealLocation
          mean
          standardDeviation
          specification
          numberOfStandardDeviationFromSpecification
        }
        productTestingRequirementsDocId
        productTestingMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        productTestingSamplingLowMediumHigh
        productTestingSamplingText5710
        productTestingSamplingTextDocId
        productTestingResultsTable {
          dut
          one
          two
          three
          four
          five
        }
      }
    }
  }
`
